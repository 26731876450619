










import { Component, Mixins, Vue } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import TestStudentHistoryHeader from '@/components/organisms/TestStudentHistoryHeader.vue'
import StudentAchievement from '@/components/organisms/StudentAchievement.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import StudentGoalApi from '@/mixins/studentGoals/StudentGoalApi'
import { StudentGoal } from '@/models/api/studentGoal'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'

@Component({
  components: {
    SidebarSwitcher,
    TestStudentHistoryHeader,
    StudentAchievement,
    ColoredBox,
  },
})
export default class TargetSituation extends Mixins(StudentGoalApi, ClassModeMethods, ClassModeChangeAble) {
  private get classMode() {
    return this.CLASS_MODE.TEST
  }

  private currentGoal: StudentGoal | null = null

  private async mounted(): Promise<void> {
    Vue.prototype.$loading.start()
    await this.checkClassModeAndSideMenuMode()
    this.currentGoal = await this.loadCurrentGoal(this.classMode)
    Vue.prototype.$loading.complete()
  }
}
